import React from "react";
import * as classes from "./style.module.css";

interface SectionProps {
  anchor: string;
  heading?: string;
  additionalClasses?: string[];
  subheader?: React.ReactNode;
  children: React.ReactNode;
}

export function Section(props: SectionProps): React.ReactElement {
  let classList;
  if (props.additionalClasses) {
    classList = props.additionalClasses
      .concat(classes.ContentWrapper)
      .join(" ");
  } else {
    classList = classes.ContentWrapper;
  }
  return (
    <section id={props.anchor} className={classes.Section}>
      <div className={classList}>
        <div style={{ display: "flex" }}>
          {props.heading && (
            <h3 className={classes.Heading}>{props.heading}</h3>
          )}
        </div>
        {props.subheader}
        {props.children}
      </div>
    </section>
  );
}
